import { Machine, assign } from 'xstate';
import type { AnyEventObject, StatesConfig, TransitionsConfig } from 'xstate';

import type { Context, Question } from './types';

export const getId = (...s: string[]) => s.join('_');

export const getUrl = (id: string) => {
  switch (id) {
    case 'PENALTY_NOTICE':
      return '/pathway/penalty-notice';
    case 'LICENCE_SUSPENSION':
      return '/pathway/licence-suspension';
    case 'COURT_ATTENDANCE_NOTICE':
      return '/pathway/court-attendance-notice';
    default:
      // eslint-disable-next-line no-console
      console.error('Received invalid pathway ID:', id);
      return '/';
  }
};

// Get the # of the question based on its type
export const getQuestionNo = (
  type: 'Offence' | 'You',
  answers: string[],
  questions: Question[]
) => {
  const previousOfType = answers.filter((answer) => {
    const question = questions.find((q) =>
      q.answers.find((a) => a.id === answer)
    );
    return question?.type === type;
  });

  return previousOfType.length + 1;
};

// Determine if an OptionResponse warning should be displayed
export const shouldShowWarning = (
  warning: { if: string[] },
  answers: string[]
): boolean => {
  // CMS user can choose "ANY" to make the warning show unconditionally
  if (warning.if.length === 1 && warning.if[0] === 'ANY') {
    return true;
  }

  return warning.if.every((answerId) => answers.includes(answerId));
};

export const createMachine = (
  id: string,
  questions: Question[],
  outcomes: string[],
  exits: string[]
) => {
  // Generate initial data
  const initialQuestion = questions[0].id;
  const initialContext: Context = {
    answers: [],
    option: null,
  };

  // Generate the machine states from the pathway data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const states: StatesConfig<Context, any, AnyEventObject> = {};

  // Generate a state for each question
  questions.forEach((question) => {
    // Generate state transitions from the question answers
    const events: TransitionsConfig<Context, AnyEventObject> = {};
    question.answers.forEach((answer) => {
      // The "next" property on an answer points to the next question
      // It can be one question ID or an array of IDs with conditions
      const next = Array.isArray(answer.next)
        ? answer.next
        : [{ id: answer.next }];

      // Generate a transition for each possible "next" question
      events[answer.id] = next.map((n) => ({
        target: n.id,
        // If the "next" has answer conditions, pass them to our custom "didAnswer" guard
        // Transition will only be possible if the guard returns true (i.e. the user did select these answers)
        cond: n.if
          ? {
              type: 'didAnswer',
              answers: n.if,
            }
          : undefined,
        // On transition, add the answer ID to the list of answers in the context
        actions: assign({
          answers: (context: Context, event) => [
            ...context.answers,
            event.type,
          ],
        }),
      }));
    });

    states[question.id] = {
      on: events,
      meta: {
        type: 'question',
        questionType: question.type,
      },
    };
  });
  // Generate a state for each exit
  exits.forEach((exit) => {
    states[exit] = {
      meta: {
        type: 'exit',
      },
    };
  });
  // Generate a state for each outcome
  outcomes.forEach((outcome) => {
    states[outcome] = {
      on: {
        // Add an "OPTION" transition which takes an option ID and stores it in the context
        OPTION: {
          target: 'OPTION',
          actions: assign({
            option: (_, event) => event.id,
          }),
        },
      },
      meta: {
        type: 'outcome',
      },
    };
  });

  return Machine<Context, AnyEventObject>(
    {
      id: id,
      initial: initialQuestion,
      context: initialContext,
      states: {
        ...states,
        // Add "OPTION" state that indicates we have selected an option (option ID is stored in context)
        OPTION: {
          meta: {
            type: 'option',
          },
        },
      },
      on: {
        // Add a global "RESET" transition that returns to the initial question and clears the context
        RESET: {
          target: initialQuestion,
          actions: assign(initialContext),
        },
      },
    },
    {
      guards: {
        // Take an array of answer IDs and return true if they all exist in the answer history
        didAnswer: (context, _, { cond }) => {
          const { answers } = cond as { type: string; answers: string[] };
          return answers.every((answerId) =>
            context.answers.includes(answerId)
          );
        },
      },
    }
  );
};
