import React from 'react';
import { Stack } from '@chakra-ui/core';
import type { StackProps } from '@chakra-ui/core';

const CardBody: React.FC<StackProps> = ({ children, ...rest }) => {
  return (
    <Stack spacing={3} {...rest}>
      {children}
    </Stack>
  );
};

export default CardBody;
