import React from 'react';
import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/core';

import { rem } from '@/utils/theme';
import { assertNever } from '@/utils/types';

type Props = {
  // Work-around to allow responsive color values
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  color?: any;
  variant: '1' | '2' | '3' | '4';
} & Omit<HeadingProps, 'color'>;

const Heading: React.FC<Props> = ({ variant, children, ...rest }) => {
  let as: 'h1' | 'h2' | 'h3' | 'h4' = 'h1';
  let color = '';
  let fontSize: string[] = [];
  switch (variant) {
    case '1':
      as = 'h1';
      fontSize = ['2xl', '2xl'];
      break;
    case '2':
      as = 'h2';
      color = 'brand.blue.primary';
      fontSize = ['xl', rem('28px')];
      break;
    case '3':
      as = 'h3';
      fontSize = ['md', 'lg'];
      break;
    case '4':
      as = 'h4';
      fontSize = ['xs'];
      break;
    default:
      assertNever(variant);
  }

  return (
    <ChakraHeading
      as={as}
      color={color}
      fontSize={fontSize}
      fontWeight={600}
      lineHeight="shorter"
      textAlign="left"
      {...rest}
    >
      {children}
    </ChakraHeading>
  );
};

export default Heading;
