import {
  ExitResponse,
  OptionResponse,
  OutcomeResponse,
  QuestionResponse,
} from '../types';

const get = (url: string): Promise<unknown> => {
  const base = process.env.SITE_URL;
  return fetch(`${base}${url}`).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json() as Promise<unknown>;
  });
};

export const fetchExit = (id: string): Promise<ExitResponse> => {
  return get(`/api/exit/${id}`).then((response) => {
    return response as ExitResponse;
  });
};

export const fetchOption = (id: string): Promise<OptionResponse> => {
  return get(`/api/option/${id}`).then((response) => {
    return response as OptionResponse;
  });
};

export const fetchOutcome = (id: string): Promise<OutcomeResponse> => {
  return get(`/api/outcome/${id}`).then((response) => {
    return response as OutcomeResponse;
  });
};

export const fetchQuestion = (id: string): Promise<QuestionResponse> => {
  return get(`/api/question/${id}`).then((response) => {
    return response as QuestionResponse;
  });
};
