import React from 'react';

import { Heading } from '@/components/typography';

type Props = Partial<React.ComponentProps<typeof Heading>>;

const CardHeading: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Heading variant="3" {...rest}>
      {children}
    </Heading>
  );
};

export default CardHeading;
