import React from 'react';
import { Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { parseHTML } from '@/utils/html';
import icons from '@/utils/icons';
import Card from '../Card';

type Props = {
  as?: 'div' | 'button';
  icon?: keyof typeof icons;
  heading: string;
  content?: string;
} & Omit<FlexProps, 'as'>;

const AnswerCard: React.FC<Props> = ({
  as = 'div',
  icon,
  heading,
  content,
  ...rest
}) => {
  return (
    <Card as={as} hasBorder {...rest}>
      <Card.Content>
        {icon && <Card.Icon name={icon} size="5" mb={4} />}
        <Stack flexGrow={1} spacing={[1, 4]}>
          <Card.Heading>{heading}</Card.Heading>
          {content && <Card.Body>{parseHTML(content)}</Card.Body>}
        </Stack>
        <Card.Icon name="arrowRight" size="5" mt={4} />
      </Card.Content>
    </Card>
  );
};

export default AnswerCard;
