/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  Collapse,
  Icon,
  Stack,
  PseudoBox,
  useDisclosure,
} from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';

import { Heading } from '@/components/typography';
import { parseHTML } from '@/utils/html';
import icons from '@/utils/icons';

type Props = {
  backgroundColor?: string;
  content: string;
  icon?: keyof typeof icons;
  iconColor?: string;
  heading: string;
  collapsible?: boolean;
} & PseudoBoxProps;

const defaultProps: Partial<Props> = {
  backgroundColor: 'brand.blue.faded',
  icon: 'info',
  iconColor: 'brand.blue.primary',
  collapsible: true,
};

const Dialog: React.FC<Props> = ({
  backgroundColor,
  icon,
  iconColor,
  heading,
  content,
  collapsible,
  ...rest
}) => {
  const { isOpen, onToggle } = useDisclosure(false);
  const contentVisible = isOpen || !collapsible;

  return (
    <PseudoBox
      backgroundColor={backgroundColor}
      border="1px solid transparent"
      display="flex"
      flexDirection="column"
      textAlign="left"
      width="full"
      _focusWithin={{
        borderColor: 'brand.blue.tertiary',
        borderWidth: '1px',
        borderRadius: 'sm',
        boxShadow: 'focus',
      }}
      css={css`
        :focus-within {
          .icon-arrow {
            color: #009ed5;
          }
        }
      `}
      {...rest}
    >
      <Stack
        as={collapsible ? 'button' : 'div'}
        aria-expanded={collapsible ? isOpen : undefined}
        onClick={collapsible ? onToggle : undefined}
        alignItems="center"
        outline="none"
        padding={3}
        spacing={3}
        width="full"
        isInline
      >
        <Icon name={icon} color={iconColor} size="4" />
        <Heading as="h2" flexGrow={1} variant="4">
          {heading}
        </Heading>
        {collapsible && (
          <Icon
            className="icon-arrow"
            name={isOpen ? 'chevronUp' : 'chevronDown'}
            color={iconColor}
            size="2"
          />
        )}
      </Stack>
      <Collapse isOpen={contentVisible}>
        <Stack pl={8} pr={collapsible ? 8 : 3} pb={3} pt={0} spacing={3}>
          {parseHTML(content)}
        </Stack>
      </Collapse>
    </PseudoBox>
  );
};

Dialog.defaultProps = defaultProps;

export default Dialog;
