import React from 'react';
import type { FlexProps } from '@chakra-ui/core';

import { parseHTML } from '@/utils/html';
import Card from '../Card';

type Props = {
  heading: string;
  content: string;
  href: string;
  imageUrl: string;
} & Omit<FlexProps, 'as'>;

const ExternalLinkCard: React.FC<Props> = ({
  heading,
  content,
  href,
  imageUrl,
  ...rest
}) => {
  return (
    <Card as="a" href={href} {...rest}>
      <Card.Image src={imageUrl} />
      <Card.Content pb={[4, 6]}>
        <Card.Heading>{heading}</Card.Heading>
        <Card.Body flexGrow={1} mt={[1, 4]}>
          {parseHTML(content)}
        </Card.Body>
        <Card.Icon name="openInNew" size="4" mt={[4, 6]} />
      </Card.Content>
    </Card>
  );
};

export default ExternalLinkCard;
