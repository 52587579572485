import React from 'react';
import { Stack } from '@chakra-ui/core';
import parse, { domToReact } from 'html-react-parser';
import type { HTMLReactParserOptions } from 'html-react-parser';
import type { Node } from 'domhandler';

import { Link } from '@/components/ui';
import { Body } from '@/components/typography';

type Options = {
  linkColor: string;
  linkUnderline: boolean;
  linkWeight: string;
  paragraphElement?: (
    children: Node[],
    options: HTMLReactParserOptions
  ) => JSX.Element;
};

const getParseOptions = (options: Options): HTMLReactParserOptions => {
  return {
    replace: ({ name, attribs, children }) => {
      if (name === 'p') {
        if (options.paragraphElement !== undefined) {
          return options.paragraphElement(children, getParseOptions(options));
        }
        return <Body>{domToReact(children, getParseOptions(options))}</Body>;
      }

      if (name === 'a') {
        return (
          <Link
            {...attribs}
            color={options.linkColor}
            fontWeight={options.linkWeight}
            textDecoration={options.linkUnderline ? 'underline' : 'none'}
            isExternal
          >
            {domToReact(children, getParseOptions(options))}
          </Link>
        );
      }

      if (name === 'ul') {
        return (
          <Stack as="ul" paddingLeft={5} spacing={3}>
            {domToReact(children, getParseOptions(options))}
          </Stack>
        );
      }

      if (name === 'li') {
        return (
          <Body as="li">{domToReact(children, getParseOptions(options))}</Body>
        );
      }

      return undefined;
    },
  };
};

export const parseHTML = (html: string, options: Partial<Options> = {}) => {
  const {
    linkColor = 'brand.blue.primary',
    linkUnderline = false,
    linkWeight = '600',
    paragraphElement = undefined,
  } = options;

  return parse(
    html,
    getParseOptions({ linkColor, linkUnderline, linkWeight, paragraphElement })
  );
};
