import React from 'react';
import { AspectRatioBox, Box, Flex, Icon } from '@chakra-ui/core';

import { Heading } from '@/components/typography';
import { rem } from '@/utils/theme';

type Props = {
  errorText: string;
  buttonText: string;
  onClick: () => void;
};

const SiteError: React.FC<Props> = ({ errorText, buttonText, onClick }) => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      maxWidth={rem('350px')}
      px={3}
      py={10}
    >
      <AspectRatioBox ratio={1.16} height={rem('100px')} width="full">
        <Icon name="error" />
      </AspectRatioBox>
      <Heading
        variant="3"
        color="brand.blue.primary"
        textAlign="center"
        marginTop={12}
        width="full"
      >
        {errorText}
      </Heading>
      <Box
        as="button"
        onClick={onClick}
        backgroundColor="brand.blue.primary"
        borderRadius="sm"
        color="white"
        fontSize="xs"
        fontWeight="600"
        marginTop={5}
        paddingX={10}
        paddingY={3}
      >
        {buttonText}
      </Box>
    </Flex>
  );
};

export default SiteError;
