import React from 'react';
import { Box, Flex, Stack, useTheme } from '@chakra-ui/core';
import converter from 'number-to-words';

import { ResponsiveStack } from '@/components/layout';
import { Heading } from '@/components/typography';
import { BackButton, OptionCard, SummaryCard } from '@/components/ui';
import { rem } from '@/utils/theme';
import { parseHTML } from '@/utils/html';
import type { CustomTheme } from '@/utils/theme';

type Props = {
  intro: string;
  summary: {
    heading: string;
    content: string;
  }[];
  options: {
    id: string;
    heading: string;
    content: string;
  }[];
  secondary?: {
    heading: string;
    options: {
      id: string;
      heading: string;
      content: string;
    }[];
  };
  onSelect: (id: string) => void;
  onBack: () => void;
};

const Outcome: React.FC<Props> = ({
  intro,
  summary,
  options,
  secondary,
  onSelect,
  onBack,
}) => {
  const theme = useTheme() as CustomTheme;

  return (
    <>
      <Flex
        alignItems="center"
        backgroundColor="brand.blue.faded"
        direction="column"
        pb={[0, 12]}
        pt={[3, 10]}
        width="full"
      >
        <Flex direction="column" maxWidth="contentMax" width="full">
          <BackButton alignSelf="flex-start" padding={3} onClick={onBack} />
          <Flex
            alignItems={['stretch', 'center']}
            direction={['column', 'row']}
            mt={[3, 6]}
            px={3}
          >
            <Stack maxWidth={rem('700px')} mr={[0, 5]} width="full">
              <Heading color="brand.text" variant="1">
                {options.length === 1 ? 'Your option' : 'Your options'}
              </Heading>
              <Stack spacing={3} mt={5}>
                {parseHTML(intro)}
              </Stack>
            </Stack>
            <Box
              flexShrink={0}
              ml={[0, 'auto']}
              mt={[12, 0]}
              width={['auto', rem('400px')]}
              position="relative"
            >
              <Box
                backgroundColor="white"
                display={['block', 'none']}
                height="50%"
                left={`-${theme.space['3']}`}
                position="absolute"
                top="50%"
                width="100vw"
              />
              <SummaryCard
                flexShrink={0}
                points={summary}
                position="relative"
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Stack alignItems="center" mt={12} pb={15} spacing={[0, 6]} width="full">
        <Stack maxWidth="contentMax" px={3} spacing={6} width="full">
          <Heading variant="2">
            You have {converter.toWords(options.length)}{' '}
            {options.length === 1 ? 'option' : 'options'}
          </Heading>
          <ResponsiveStack cols={3} spacing={0} colSpacing={3}>
            {options.map((option, i) => (
              <OptionCard
                key={option.id}
                heading={option.heading}
                content={option.content}
                firstChildInStack={i === 0}
                onClick={() => {
                  onSelect(option.id);
                }}
              />
            ))}
          </ResponsiveStack>
        </Stack>
        {secondary !== undefined && (
          <Stack
            direction="column"
            maxWidth="contentMax"
            px={3}
            spacing={6}
            width="full"
          >
            <Heading variant="2">{secondary.heading}</Heading>
            <ResponsiveStack cols={3} spacing={0} colSpacing={3}>
              {secondary.options.map((option, i) => (
                <OptionCard
                  key={option.id}
                  heading={option.heading}
                  content={option.content}
                  firstChildInStack={i === 0}
                  onClick={() => {
                    onSelect(option.id);
                  }}
                />
              ))}
            </ResponsiveStack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Outcome;
