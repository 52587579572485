import React from 'react';
import { Text, BoxProps } from '@chakra-ui/core';

type Props = BoxProps;

const Body: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Text fontSize="xs" lineHeight="shorter" textAlign="left" {...rest}>
      {children}
    </Text>
  );
};

export default Body;
