import React from 'react';
import { Stack } from '@chakra-ui/core';

import { Heading } from '@/components/typography';
import { Loader } from '@/components/ui';
import { rem } from '@/utils/theme';

type Props = {
  text?: string;
  disableAnimation?: boolean;
};

const Calculating: React.FC<Props> = ({ text, disableAnimation }) => {
  return (
    <Stack
      alignItems="center"
      px={3}
      py={10}
      maxWidth={rem('350px')}
      spacing={5}
    >
      <Loader width={rem('128px')} disableAnimation={disableAnimation} />
      <Heading
        variant="3"
        color="brand.blue.primary"
        textAlign="center"
        width="full"
      >
        {text}
      </Heading>
    </Stack>
  );
};

export default Calculating;
