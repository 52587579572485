import React from 'react';
import Link from 'next/link';
import { Box, Flex, Stack } from '@chakra-ui/core';

import { ResponsiveStack } from '@/components/layout';
import { Heading } from '@/components/typography';
import { AnswerCard, BackButton, ExternalLinkCard } from '@/components/ui';
import { getUrl } from '@/pathways/utils';
import { rem } from '@/utils/theme';
import { parseHTML } from '@/utils/html';
import icons from '@/utils/icons';

type Props = {
  page: {
    heading: string;
    explanation: string;
  };
  links: {
    heading: string;
    startingPoints?: {
      id: string;
      icon: string;
      heading: string;
      description: string;
    }[];
    resources?: {
      heading: string;
      description: string;
      url: string;
      image: string;
    }[];
  };
  onBack: () => void;
};

const Exit: React.FC<Props> = ({ page, links, onBack }) => {
  return (
    <>
      <Flex
        alignItems="center"
        backgroundColor="brand.blue.primary"
        color="white"
        direction="column"
        pb={12}
        width="full"
      >
        <Flex
          direction="column"
          maxWidth="contentMax"
          paddingTop={[3, 10]}
          width="full"
        >
          <BackButton
            iconColor="white"
            alignSelf="flex-start"
            padding={3}
            onClick={onBack}
          />
          <Flex direction="column" px={3} mt={[5, 6]}>
            <Heading variant="1">{page.heading}</Heading>
            <Stack spacing={3} mt={5}>
              {parseHTML(page.explanation, {
                linkColor: 'white',
                linkUnderline: true,
                linkWeight: '400',
              })}
            </Stack>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        direction="column"
        pb={10}
        position="relative"
        width="full"
      >
        <Box
          backgroundColor="brand.blue.primary"
          height={[rem('150px'), rem('200px')]}
          left={0}
          position="absolute"
          width="full"
        />
        <Flex
          direction="column"
          px={3}
          maxWidth="contentMax"
          position="relative"
          width="full"
        >
          <Heading color="white" variant="2">
            {links.heading}
          </Heading>
          <ResponsiveStack cols={3} colSpacing={3} rowSpacing={5} mt={[4, 8]}>
            {links.resources &&
              links.resources.map((resource) => (
                <ExternalLinkCard
                  key={resource.url}
                  href={resource.url}
                  imageUrl={resource.image}
                  heading={resource.heading}
                  content={resource.description}
                />
              ))}
            {links.startingPoints &&
              links.startingPoints.map((point) => (
                <Link
                  key={point.id}
                  href="/pathway/[...slug]"
                  as={getUrl(point.id)}
                  passHref
                >
                  <Box as="a" width="full">
                    <AnswerCard
                      icon={point.icon as keyof typeof icons}
                      heading={point.heading}
                      content={point.description}
                    />
                  </Box>
                </Link>
              ))}
          </ResponsiveStack>
        </Flex>
      </Flex>
    </>
  );
};

export default Exit;
