/** @jsx jsx */
import React from 'react';
import { Box, Flex, useTheme } from '@chakra-ui/core';
import { css, jsx } from '@emotion/core';

import { rem } from '@/utils/theme';
import type { CustomTheme } from '@/utils/theme';
import { assertNever, Step } from '@/utils/types';
import StepItem from './StepItem';

type Props = {
  step: Step;
  question: number;
};

const progressBarHeight = rem('6px');

const getProgressBarWidth = (step: Step, question: number) => {
  let progress = 0;
  switch (step) {
    case Step.Offence:
      // Progress starts at 10% on first question of 'Offence'
      progress = 0;
      break;
    case Step.You:
      // Progress starts at 50% on first question of 'You'
      progress = 4 / 10;
      break;
    case Step.Options:
      // Progress is 100% on 'Options' (no questions)
      progress = 1;
      break;
    default:
      assertNever(step);
  }
  // Each question (up to 3) adds 10% to the progress bar
  if (progress < 1) {
    progress += Math.min(question, 3) * 0.1;
  }
  return `${progress * 100}%`;
};

const StepIndicator: React.FC<Props> = ({ step, question }) => {
  const theme = useTheme() as CustomTheme;
  const paddingBreakpoint = `${
    parseFloat(theme.sizes.contentMax) + parseFloat(theme.space['3'])
  }rem`;

  return (
    <Flex
      alignItems="center"
      backgroundColor="brand.blue.primary"
      direction="column"
      width="full"
    >
      <Flex maxWidth="contentMax" width="full">
        <Flex
          direction="column"
          flexGrow={1}
          maxWidth={rem('400px')}
          position="relative"
          px={3}
          css={css`
            @media (min-width: ${paddingBreakpoint}) {
              padding-left: 0;
              padding-right: 0;
            }
          `}
        >
          <Flex fontSize="xs" justifyContent="space-between" width="full">
            <StepItem num={1} active={step === Step.Offence}>
              Offence
            </StepItem>
            <StepItem num={2} active={step === Step.You}>
              You
            </StepItem>
            <StepItem num={3} active={step === Step.Options}>
              Options
            </StepItem>
          </Flex>
          <Box
            backgroundColor="brand.blue.primary"
            height={progressBarHeight}
            left={0}
            opacity={0.5}
            position="absolute"
            transition="width 0.3s"
            top="100%"
            width={getProgressBarWidth(step, question)}
          />
        </Flex>
      </Flex>
      <Box backgroundColor="white" height={progressBarHeight} width="full" />
    </Flex>
  );
};

export default StepIndicator;
