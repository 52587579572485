import React from 'react';

import { Flex, Icon, Text, FlexProps } from '@chakra-ui/core';

type Props = {
  iconColor?: string;
  onClick: () => void;
} & FlexProps;

const BackButton: React.FC<Props> = ({
  iconColor = 'brand.blue.primary',
  onClick,
  ...rest
}) => {
  return (
    <Flex
      as="button"
      alignItems="center"
      fontSize="xs"
      onClick={onClick}
      {...rest}
    >
      <Icon color={iconColor} name="arrowLeft" mr={1} />
      <Text fontWeight="600">Back</Text>
    </Flex>
  );
};

export default BackButton;
