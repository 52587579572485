import { createMachine, getId } from '../utils';
import type { Question } from '../types';

export const id = 'PENALTY_NOTICE';

export const keys = {
  questions: {
    offenceType: getId(id, 'OFFENCE_TYPE'),
    drinkDrivingDetails: getId(id, 'DRINK_DRIVING_DETAILS'),
    drugDrivingDetails: getId(id, 'DRUG_DRIVING_DETAILS'),
    licenceDetails: getId(id, 'LICENCE_DETAILS'),
    speedingDetails: getId(id, 'SPEEDING_DETAILS'),
    didCommit: getId(id, 'DID_COMMIT'),
  },
  answers: {
    offenceType: {
      drinkDriving: getId(id, 'OFFENCE_TYPE', 'DRINK_DRIVING'),
      drugDriving: getId(id, 'OFFENCE_TYPE', 'DRUG_DRIVING'),
      drivingWithoutLicence: getId(
        id,
        'OFFENCE_TYPE',
        'DRIVING_WITHOUT_LICENCE'
      ),
      drivingUnregisteredVehicle: getId(
        id,
        'OFFENCE_TYPE',
        'DRIVING_UNREGISTERED_VEHICLE'
      ),
      parkingOffence: getId(id, 'OFFENCE_TYPE', 'PARKING_OFFENCE'),
      mobilePhoneUse: getId(id, 'OFFENCE_TYPE', 'MOBILE_PHONE_USE'),
      speeding: getId(id, 'OFFENCE_TYPE', 'SPEEDING'),
      other: getId(id, 'OFFENCE_TYPE', 'OTHER'),
    },
    drinkDrivingDetails: {
      lowRange: getId(id, 'DRINK_DRIVING_DETAILS', 'LOW_RANGE'),
      specialRange: getId(id, 'DRINK_DRIVING_DETAILS', 'SPECIAL_RANGE'),
      noviceRange: getId(id, 'DRINK_DRIVING_DETAILS', 'NOVICE_RANGE'),
      midRange: getId(id, 'DRINK_DRIVING_DETAILS', 'MID_RANGE'),
      highRange: getId(id, 'DRINK_DRIVING_DETAILS', 'HIGH_RANGE'),
    },
    drugDrivingDetails: {
      inSystem: getId(id, 'DRUG_DRIVING_DETAILS', 'IN_SYSTEM'),
      underInfluence: getId(id, 'DRUG_DRIVING_DETAILS', 'UNDER_INFLUENCE'),
      refusalToGiveSamples: getId(
        id,
        'DRUG_DRIVING_DETAILS',
        'REFUSAL_TO_GIVE_SAMPLES'
      ),
    },
    licenceDetails: {
      unlicenced: getId(id, 'LICENCE_DETAILS', 'UNLICENCED'),
      neverLicenced: getId(id, 'LICENCE_DETAILS', 'NEVER_LICENCED'),
      suspendedLicence: getId(id, 'LICENCE_DETAILS', 'SUSPENDED_LICENCE'),
    },
    speedingDetails: {
      exceedUnder10Kmh: getId(id, 'SPEEDING_DETAILS', 'UNDER_10KMH'),
      exceedOver10Kmh: getId(id, 'SPEEDING_DETAILS', 'OVER_10KMH'),
      exceedOver20Kmh: getId(id, 'SPEEDING_DETAILS', 'OVER_20KMH'),
      exceedOver30Kmh: getId(id, 'SPEEDING_DETAILS', 'OVER_30KMH'),
      exceedOver45Kmh: getId(id, 'SPEEDING_DETAILS', 'OVER_45KMH'),
    },
    didCommit: {
      yes: getId(id, 'DID_COMMIT', 'YES'),
      no: getId(id, 'DID_COMMIT', 'NO'),
    },
  },
  outcomes: {
    // Did commit the offence
    // Pay your fine OR Apply for a penalty review OR Try to reduce the fine in court
    one: getId(id, 'OUTCOME', 'ONE'),
    // Didn't commit the offence
    // Name another driver OR challenge your offence in court OR Apply for a penalty review
    two: getId(id, 'OUTCOME', 'TWO'),
    // Drink driving and did commit the offence
    // Adds "Appeal the suspension" option to the above "did commit the offence" list
    three: getId(id, 'OUTCOME', 'THREE'),
    // Drink driving and didn't commit the offence
    // Adds "Appeal the suspension" option to the above "didn't commit the offence" list
    four: getId(id, 'OUTCOME', 'FOUR'),
  },
  exits: {
    // Get legal advice
    drugDrivingUnderInfluence: getId(
      id,
      'EXIT',
      'DRUG_DRIVING_UNDER_INFLUENCE'
    ),
    // Get legal advice
    refusalToGiveSamples: getId(id, 'EXIT', 'REFUSAL_TO_GIVE_SAMPLES'),
    // Get legal advice
    drinkDrivingMidHigh: getId(id, 'EXIT', 'DRINK_DRIVING_MID_HIGH'),
    // Get legal advice
    speeding45KmhOver: getId(id, 'EXIT', 'SPEEDING_45KMH_OVER'),
    // Get legal advice
    drivingWithSuspendedLicence: getId(
      id,
      'EXIT',
      'DRIVING_WITH_SUSPENDING_LICENCE'
    ),
    // Get legal advice
    other: getId(id, 'EXIT', 'OTHER'),
  },
};

export const questions: Question[] = [
  {
    // What type of offence have you been charged with?
    id: keys.questions.offenceType,
    type: 'Offence',
    answers: [
      {
        // Drink driving
        id: keys.answers.offenceType.drinkDriving,
        next: keys.questions.drinkDrivingDetails,
      },
      {
        // Drug driving
        id: keys.answers.offenceType.drugDriving,
        next: keys.questions.drugDrivingDetails,
      },
      {
        // Driving without a licence
        id: keys.answers.offenceType.drivingWithoutLicence,
        next: keys.questions.licenceDetails,
      },
      {
        // Driving an unregistered and/or uninsured vehicle
        id: keys.answers.offenceType.drivingUnregisteredVehicle,
        next: keys.questions.didCommit,
      },
      {
        // Parking offence
        id: keys.answers.offenceType.parkingOffence,
        next: keys.questions.didCommit,
      },
      {
        // Mobile phone use
        id: keys.answers.offenceType.mobilePhoneUse,
        next: keys.questions.didCommit,
      },
      {
        // Speeding
        id: keys.answers.offenceType.speeding,
        next: keys.questions.speedingDetails,
      },
      {
        // Other
        id: keys.answers.offenceType.other,
        next: keys.exits.other,
      },
    ],
  },
  {
    // What are the details of your offence?
    id: keys.questions.drinkDrivingDetails,
    type: 'Offence',
    answers: [
      {
        // Low range prescribed concentration of alcohol (PCA)
        id: keys.answers.drinkDrivingDetails.lowRange,
        next: keys.questions.didCommit,
      },
      {
        // Special range prescribed concentration of alcohol (PCA)
        id: keys.answers.drinkDrivingDetails.specialRange,
        next: keys.questions.didCommit,
      },
      {
        // Novice range prescribed concentration of alcohol (PCA)
        id: keys.answers.drinkDrivingDetails.noviceRange,
        next: keys.questions.didCommit,
      },
      {
        // Mid range prescribed concentration of alcohol (PCA)
        id: keys.answers.drinkDrivingDetails.midRange,
        next: keys.exits.drinkDrivingMidHigh,
      },
      {
        // High range prescribed concentration of alcohol (PCA)
        id: keys.answers.drinkDrivingDetails.highRange,
        next: keys.exits.drinkDrivingMidHigh,
      },
    ],
  },
  {
    // What are the details of your offence?
    id: keys.questions.drugDrivingDetails,
    type: 'Offence',
    answers: [
      {
        // Driving with illicit drugs in oral fluid, blood or urine
        id: keys.answers.drugDrivingDetails.inSystem,
        next: keys.questions.didCommit,
      },
      {
        // Driving under the influence of drugs
        id: keys.answers.drugDrivingDetails.underInfluence,
        next: keys.exits.drugDrivingUnderInfluence,
      },
      {
        // Refusal to give samples
        id: keys.answers.drugDrivingDetails.refusalToGiveSamples,
        next: keys.exits.refusalToGiveSamples,
      },
    ],
  },
  {
    // What are the details of your offence?
    id: keys.questions.licenceDetails,
    type: 'Offence',
    answers: [
      {
        // Unlicenced
        id: keys.answers.licenceDetails.unlicenced,
        next: keys.questions.didCommit,
      },
      {
        // Never licenced
        id: keys.answers.licenceDetails.neverLicenced,
        next: keys.questions.didCommit,
      },
      {
        // Disqualified, suspended, cancelled or refused licence
        id: keys.answers.licenceDetails.suspendedLicence,
        next: keys.exits.drivingWithSuspendedLicence,
      },
    ],
  },
  {
    // What are the details of your offence?
    id: keys.questions.speedingDetails,
    type: 'Offence',
    answers: [
      {
        // Exceed speed 10 km/h and under
        id: keys.answers.speedingDetails.exceedUnder10Kmh,
        next: keys.questions.didCommit,
      },
      {
        // Exceed speed over 10 km/h
        id: keys.answers.speedingDetails.exceedOver10Kmh,
        next: keys.questions.didCommit,
      },
      {
        // Exceed speed over 20 km/h
        id: keys.answers.speedingDetails.exceedOver20Kmh,
        next: keys.questions.didCommit,
      },
      {
        // Exceed speed over 30 km/h
        id: keys.answers.speedingDetails.exceedOver30Kmh,
        next: keys.questions.didCommit,
      },
      {
        // Exceed speed over 45 km/h
        id: keys.answers.speedingDetails.exceedOver45Kmh,
        next: keys.exits.speeding45KmhOver,
      },
    ],
  },
  {
    // Did you commit the offence?
    id: keys.questions.didCommit,
    type: 'You',
    answers: [
      {
        // Yes
        id: keys.answers.didCommit.yes,
        next: [
          {
            id: keys.outcomes.three,
            if: [keys.answers.drinkDrivingDetails.lowRange],
          },
          {
            id: keys.outcomes.three,
            if: [keys.answers.drinkDrivingDetails.noviceRange],
          },
          {
            id: keys.outcomes.three,
            if: [keys.answers.drinkDrivingDetails.specialRange],
          },
          {
            id: keys.outcomes.one,
          },
        ],
      },
      {
        // No
        id: keys.answers.didCommit.no,
        next: [
          {
            id: keys.outcomes.four,
            if: [keys.answers.drinkDrivingDetails.lowRange],
          },
          {
            id: keys.outcomes.four,
            if: [keys.answers.drinkDrivingDetails.noviceRange],
          },
          {
            id: keys.outcomes.four,
            if: [keys.answers.drinkDrivingDetails.specialRange],
          },
          {
            id: keys.outcomes.two,
          },
        ],
      },
    ],
  },
];

export const outcomes = Object.values(keys.outcomes);

export const exits = Object.values(keys.exits);

export const machine = createMachine(id, questions, outcomes, exits);
