/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  Collapse,
  Icon,
  Stack,
  PseudoBox,
  useDisclosure,
  useTheme,
} from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';

import { Heading } from '@/components/typography';
import { parseHTML } from '@/utils/html';
import type { CustomTheme } from '@/utils/theme';

type Props = {
  heading: string;
  content: string;
} & PseudoBoxProps;

const Accordion: React.FC<Props> = ({ heading, content, ...rest }) => {
  const theme = useTheme() as CustomTheme;
  const { isOpen, onToggle } = useDisclosure(false);

  return (
    <PseudoBox
      border="1px solid"
      borderColor="transparent"
      borderBottomColor="gray.200"
      borderBottomWidth="2px"
      display="flex"
      flexDirection="column"
      width="full"
      _last={{ borderBottomColor: 'transparent' }}
      _focusWithin={{
        borderColor: 'brand.blue.tertiary',
        borderWidth: '1px',
        borderRadius: 'sm',
        boxShadow: 'focus',
        marginBottom: '1px',
      }}
      css={css`
        :focus-within {
          .icon-arrow {
            color: ${theme.colors.brand.blue.tertiary};
          }
        }
      `}
      {...rest}
    >
      <Stack
        as="button"
        aria-expanded={isOpen}
        onClick={onToggle}
        px={3}
        py={5}
        outline="none"
        spacing={5}
        textAlign="left"
        width="full"
        isInline
      >
        <Heading flexGrow={1} variant="3">
          {heading}
        </Heading>
        <Icon
          className="icon-arrow"
          name={isOpen ? 'chevronUp' : 'chevronDown'}
          color="brand.blue.primary"
          mt={1}
          size="2"
        />
      </Stack>
      <Collapse isOpen={isOpen}>
        <Stack px={3} pb={5} pt={0} spacing={3} width="full">
          {parseHTML(content)}
        </Stack>
      </Collapse>
    </PseudoBox>
  );
};

export default Accordion;
