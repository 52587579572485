import React from 'react';
import { css, keyframes } from '@emotion/core';
import { AspectRatioBox, Icon } from '@chakra-ui/core';
import type { AspectRatioBoxProps } from '@chakra-ui/core';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
`;

type Props = {
  disabled?: boolean;
  disableAnimation?: boolean;
} & AspectRatioBoxProps;

const Loader: React.FC<Props> = ({
  disabled = false,
  disableAnimation = false,
  ...rest
}) => {
  const animate = !disabled && !disableAnimation;
  return (
    <AspectRatioBox ratio={1} overflow="hidden" width="full" {...rest}>
      <Icon
        name={disabled ? 'loaderDisabled' : 'loader'}
        css={
          animate
            ? css`
                animation: ${spin} 2s linear infinite;
              `
            : undefined
        }
      />
    </AspectRatioBox>
  );
};

export default Loader;
