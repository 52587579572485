import {
  courtAttendanceNotice,
  licenceSuspension,
  penaltyNotice,
} from './data';

type Summary = {
  heading: string;
  content: string;
}[];

// Licence Suspension
const generateLicenceSuspensionSummary = (answers: string[]): Summary => {
  const summary: Summary = [];
  const keys = licenceSuspension.keys.answers;
  const answered = (...as: string[]) => as.every((a) => answers.includes(a));
  const add = (heading: string, content: string) =>
    summary.push({ heading, content });

  // "Suspension reason"
  if (answered(keys.reason.speeding)) {
    add('Suspension reason', 'Speeding');
  } else if (answered(keys.reason.demerits)) {
    add('Suspension reason', 'Demerit points');
  }

  // "Issued"
  if (answered(keys.moreThan28DaysAgo.yes)) {
    add('Issued', 'More than 28 days ago');
  } else if (answered(keys.moreThan28DaysAgo.no)) {
    add('Issued', 'Less than 28 days ago');
  }

  // "Licence type"
  if (answered(keys.licenceType.learner)) {
    add('Licence type', 'Learner');
  } else if (answered(keys.licenceType.p1)) {
    add('Licence type', 'Provisional 1');
  } else if (answered(keys.licenceType.p2)) {
    add('Licence type', 'Provisional 2');
  } else if (answered(keys.licenceType.full)) {
    add('Licence type', 'Full');
  }

  return summary;
};

// Utility for "Penalty Notice" and "Court Attendance Notice" pathways
const generateNoticeSummary = (
  answers: string[],
  keys:
    | typeof penaltyNotice.keys.answers
    | typeof courtAttendanceNotice.keys.answers
): Summary => {
  const summary: Summary = [];
  const answered = (...as: string[]) => as.every((a) => answers.includes(a));
  const add = (heading: string, content: string) =>
    summary.push({ heading, content });

  // "Offence"
  if (answered(keys.offenceType.drinkDriving)) {
    if (answered(keys.drinkDrivingDetails.lowRange)) {
      add('Offence', 'Drink driving, Low-Range PCA');
    } else if (answered(keys.drinkDrivingDetails.noviceRange)) {
      add('Offence', 'Drink driving, Novice-Range PCA');
    } else if (answered(keys.drinkDrivingDetails.specialRange)) {
      add('Offence', 'Drink driving, Special-Range PCA');
    }
  } else if (answered(keys.offenceType.speeding)) {
    if (answered(keys.speedingDetails.exceedUnder10Kmh)) {
      add('Offence', 'Speeding 10 km/h or under');
    } else if (answered(keys.speedingDetails.exceedOver10Kmh)) {
      add('Offence', 'Speeding more than 10 km/h over');
    } else if (answered(keys.speedingDetails.exceedOver20Kmh)) {
      add('Offence', 'Speeding more than 20 km/h over');
    } else if (answered(keys.speedingDetails.exceedOver30Kmh)) {
      add('Offence', 'Speeding more than 30km/h over');
    } else if (answered(keys.speedingDetails.exceedOver45Kmh)) {
      add('Offence', 'Speeding more than 45km/h over');
    }
  } else if (answered(keys.offenceType.drugDriving)) {
    add('Offence', 'Drug driving');
  } else if (answered(keys.offenceType.drivingWithoutLicence)) {
    add('Offence', 'Driving without licence');
  } else if (answered(keys.offenceType.parkingOffence)) {
    add('Offence', 'Parking offence');
  } else if (answered(keys.offenceType.drivingUnregisteredVehicle)) {
    add('Offence', 'Driving an unregistered and/or uninsured vehicle');
  } else if (answered(keys.offenceType.mobilePhoneUse)) {
    add('Offence', 'Mobile phone use');
  }

  // "History" (Question only exists on "Court Attendance" pathway)
  if ('committedPreviouslyInPast5Years' in keys) {
    if (answered(keys.committedPreviouslyInPast5Years.yes)) {
      add('History', 'Prior offence');
    } else if (answered(keys.committedPreviouslyInPast5Years.no)) {
      add('History', 'No prior offences');
    }
  }

  // "Responsibility"
  if (answered(keys.didCommit.yes)) {
    add('Responsibility', 'Not contesting offence');
  } else if (answered(keys.didCommit.no)) {
    add('Responsibility', 'Contesting offence');
  }

  return summary;
};

// Penalty Notice
const generatePenaltyNoticeSummary = (answers: string[]): Summary => {
  return generateNoticeSummary(answers, penaltyNotice.keys.answers);
};

// Court Attendance Notice
const generateCourtAttendanceNoticeSummary = (answers: string[]): Summary => {
  return generateNoticeSummary(answers, courtAttendanceNotice.keys.answers);
};

export const generateSummary = (id: string, answers: string[]): Summary => {
  switch (id) {
    case 'PENALTY_NOTICE':
      return generatePenaltyNoticeSummary(answers);
    case 'LICENCE_SUSPENSION':
      return generateLicenceSuspensionSummary(answers);
    case 'COURT_ATTENDANCE_NOTICE':
      return generateCourtAttendanceNoticeSummary(answers);
    default:
      // eslint-disable-next-line no-console
      console.error('Received invalid pathway ID:', id);
      return [];
  }
};
