import React from 'react';
import { AspectRatioBox, Box, Flex, Stack } from '@chakra-ui/core';

import { ResponsiveStack } from '@/components/layout';
import { Heading } from '@/components/typography';
import {
  Accordion,
  BackButton,
  Dialog,
  ExternalLinkCard,
  Image,
} from '@/components/ui';
import { parseHTML } from '@/utils/html';
import { rem } from '@/utils/theme';

type Props = {
  page: {
    image: string;
    heading: string;
    description: string;
  };
  warnings: {
    id: string;
    heading: string;
    content: string;
  }[];
  considerations: {
    heading: string;
    content: string;
  }[];
  resources: {
    heading: string;
    description: string;
    url: string;
    image: string;
  }[];
  onBack: () => void;
};

const OptionDetails: React.FC<Props> = ({
  page,
  warnings,
  considerations,
  resources,
  onBack,
}) => {
  return (
    <>
      <Flex direction="column" maxWidth="contentMax" width="full">
        <Flex alignItems="flex-start" width="full">
          <Flex
            direction="column"
            maxWidth={['none', rem('510px')]}
            paddingTop={[3, 10]}
            width={['full', '50%']}
          >
            <BackButton alignSelf="flex-start" padding={3} onClick={onBack} />
            <Image
              alt=""
              src={page.image}
              display={['block', 'none']}
              height={rem('240px')}
              objectFit="cover"
              width="full"
            />
            <Flex direction="column" px={3} pt={5}>
              <Heading variant="1">{page.heading}</Heading>
              {warnings.length > 0 && (
                <Stack mt={5} spacing={3}>
                  {warnings.map((warning) => (
                    <Dialog
                      key={warning.id}
                      backgroundColor="brand.orange.faded"
                      icon="warning"
                      iconColor="brand.orange.primary"
                      maxWidth={[rem('400px'), 'none']}
                      heading={warning.heading}
                      content={warning.content}
                      collapsible={false}
                    />
                  ))}
                </Stack>
              )}
              <Stack spacing={3} mt={5}>
                {parseHTML(page.description)}
              </Stack>
            </Flex>
          </Flex>
          <AspectRatioBox
            display={['none', 'block']}
            ml="auto"
            ratio={0.9}
            width="50%"
          >
            <Image alt="" src={page.image} objectFit="cover" />
          </AspectRatioBox>
        </Flex>
        <Flex
          direction="column"
          mt={12}
          maxWidth={['none', rem('510px')]}
          width={['full', '50%']}
        >
          <Heading px={3} variant="2">
            Things to consider
          </Heading>
          {considerations.map((consideration) => (
            <Accordion
              key={consideration.heading}
              heading={consideration.heading}
              content={consideration.content}
            />
          ))}
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        backgroundColor="brand.blue.faded"
        direction="column"
        flexGrow={1}
        px={3}
        pb={10}
        position="relative"
        mt={12}
        width="full"
      >
        <Box
          backgroundColor="white"
          height={[rem('100px'), rem('140px')]}
          left={0}
          position="absolute"
          width="full"
        />
        <Flex
          direction="column"
          maxWidth="contentMax"
          position="relative"
          width="full"
        >
          <Heading variant="2">Next steps</Heading>
          <ResponsiveStack cols={3} colSpacing={3} rowSpacing={5} mt={4}>
            {resources.map((resource) => (
              <ExternalLinkCard
                key={resource.url}
                href={resource.url}
                imageUrl={resource.image}
                heading={resource.heading}
                content={resource.description}
              />
            ))}
          </ResponsiveStack>
        </Flex>
      </Flex>
    </>
  );
};

export default OptionDetails;
