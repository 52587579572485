import React from 'react';
import { Image as ChakraImage } from '@chakra-ui/core';
import type { ImageProps } from '@chakra-ui/core';

type Props = {
  objectFit: 'none' | 'cover' | 'contain';
} & Omit<ImageProps, 'objectFit'>;

// Prevents "broken image" icon
const transparentPixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const Image: React.FC<Props> = ({ objectFit, src, ...rest }) => {
  // Fallback to background image because object-fit isn't supported in IE11
  if (objectFit !== 'none') {
    return (
      <ChakraImage
        src={transparentPixel}
        backgroundImage={`url(${src})`}
        backgroundPosition="50% 50%"
        backgroundSize={objectFit}
        objectFit="none"
        {...rest}
      />
    );
  }

  return <ChakraImage src={src} objectFit={objectFit} {...rest} />;
};

export default Image;
