import React from 'react';
import { Link as ChakraLink } from '@chakra-ui/core';
import type { LinkProps } from '@chakra-ui/core';

const Link: React.FC<LinkProps> = ({ ...rest }) => {
  return (
    <ChakraLink
      // Reset custom focus styling from Chakra UI
      outline={undefined}
      transition={undefined}
      _focus={undefined}
      {...rest}
    />
  );
};

export default Link;
