import React from 'react';
import { Flex, Text } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { parseHTML } from '@/utils/html';
import Card from '../Card';

type Props = {
  heading: string;
  content: string;
  firstChildInStack: boolean;
  onClick: () => void;
} & Omit<FlexProps, 'as'>;

const OptionCard: React.FC<Props> = ({
  heading,
  content,
  firstChildInStack = false,
  onClick,
  ...rest
}) => {
  return (
    <Card
      as="button"
      onClick={onClick}
      borderTopWidth={[firstChildInStack ? '0' : '6px', '6px']}
      boxShadow="none"
      p={0}
      pb={6}
      pt={[firstChildInStack ? 0 : 6, 6]}
      hasBorder
      {...rest}
    >
      <Card.Heading>{heading}</Card.Heading>
      <Card.Body flexGrow={1} mt={3}>
        {parseHTML(content)}
      </Card.Body>
      <Flex alignItems="center" fontSize="xs" mt={5}>
        <Text fontWeight="600" mr={1}>
          Find out more
        </Text>
        <Card.Icon name="arrowRight" />
      </Flex>
    </Card>
  );
};

export default OptionCard;
