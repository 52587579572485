/* eslint-disable global-require */
/** @jsx jsx */
import React from 'react';
import Link from 'next/link';
import { Box, Divider, Flex, Stack, useTheme } from '@chakra-ui/core';
import { css, jsx } from '@emotion/core';
import { domToReact } from 'html-react-parser';

import { ResponsiveStack } from '@/components/layout';
import { Heading } from '@/components/typography';
import { AnswerCard, ExternalLinkCard, Image } from '@/components/ui';
import { getUrl } from '@/pathways/utils';
import { rem } from '@/utils/theme';
import { parseHTML } from '@/utils/html';
import icons from '@/utils/icons';
import type { CustomTheme } from '@/utils/theme';

type Props = {
  page: {
    heading: string;
    intro: string;
    explanation: string;
  };
  startingPoints: {
    heading: string;
    explanation: string;
    items: {
      id: string;
      icon: string;
      heading: string;
      description: string;
    }[];
  };
  resources: {
    heading: string;
    items: {
      heading: string;
      description: string;
      url: string;
      image: string;
    }[];
  };
};

const Home: React.FC<Props> = ({ page, startingPoints, resources }) => {
  const theme = useTheme() as CustomTheme;
  const contentMax = theme.sizes.contentMax;

  return (
    // Must use <React.Fragment> instead of <> when using emotion css prop
    // https://github.com/emotion-js/emotion/issues/1549
    <React.Fragment>
      <Box
        backgroundColor="white"
        overflowX="hidden"
        px={[0, 3]}
        width="full"
        css={css`
          @media (max-width: ${contentMax}) {
            padding-right: 0;
          }
        `}
      >
        <Flex
          alignItems="stretch"
          backgroundColor="white"
          direction={['column', 'row-reverse']}
          margin="0 auto"
          maxWidth="contentMax"
        >
          <Image
            alt=""
            src={require('@/images/person-driving-car.jpg')}
            borderBottom={['6px solid', 'none']}
            borderColor="brand.red.primary"
            flexShrink={0}
            height={[rem('240px'), 'auto']}
            objectFit="cover"
            width={['auto', '50%']}
            zIndex={1}
          />
          <Flex direction="column" width={['auto', '50%']}>
            <Stack
              flexGrow={1}
              justifyContent="center"
              maxWidth={['none', rem('470px')]}
              spacing={5}
              px={[3, 0]}
              pt={[5, 12]}
              pb={[10, 14]}
            >
              <Heading variant="1">{page.heading}</Heading>
              <Stack spacing={3} mt={[0, 6]}>
                {parseHTML(page.intro, {
                  paragraphElement: (children, options) => {
                    return (
                      <Heading as="h2" variant="3">
                        {domToReact(children, options)}
                      </Heading>
                    );
                  },
                })}
              </Stack>
              <Stack spacing={3}>{parseHTML(page.explanation)}</Stack>
            </Stack>
            <Box
              backgroundColor="brand.blue.primary"
              height={[0, 10]}
              left="-100vw"
              position="relative"
              width="200vw"
            />
          </Flex>
        </Flex>
      </Box>
      <Box backgroundColor="brand.blue.primary" px={3} width="full">
        <Flex direction="column" margin="0 auto" maxWidth="contentMax">
          <Stack
            boxSizing="content-box"
            color="white"
            pt={10}
            pb={[6, 13]}
            maxWidth={rem('800px')}
            spacing={[4, 5]}
          >
            <Heading color="white" variant="2">
              {startingPoints.heading}
            </Heading>
            <Stack spacing={3}>{parseHTML(startingPoints.explanation)}</Stack>
          </Stack>
        </Flex>
      </Box>
      <Box
        backgroundColor="brand.blue.primary"
        pb={[12, 0]}
        px={3}
        position="relative"
        width="full"
      >
        <Box
          backgroundColor="brand.blue.faded"
          display={['none', 'block']}
          height="50%"
          left={0}
          position="absolute"
          top="50%"
          width="full"
        />
        <ResponsiveStack
          cols={3}
          spacing={4}
          margin="0 auto"
          maxWidth="contentMax"
          position="relative"
          zIndex={1}
        >
          {startingPoints.items.map((point) => (
            <Link
              key={point.id}
              href="/pathway/[...slug]"
              as={getUrl(point.id)}
              passHref
            >
              <Box as="a" width="full">
                <AnswerCard
                  icon={point.icon as keyof typeof icons}
                  heading={point.heading}
                  content={point.description}
                />
              </Box>
            </Link>
          ))}
        </ResponsiveStack>
      </Box>
      <Divider
        borderColor="white"
        display={['none', 'block']}
        opacity={1}
        maxWidth="contentMax"
        mx={13}
        my={12}
        width="full"
      />
      <Box px={3} pb={[12, 13]} position="relative" width="full">
        <Box
          backgroundColor="brand.blue.primary"
          height={[rem('200px'), 0]}
          left={0}
          position="absolute"
          top="0"
          width="full"
        />
        <Stack
          margin="0 auto"
          maxWidth="contentMax"
          position="relative"
          spacing={[4, 12]}
        >
          <Heading color={['white', 'brand.text']} variant="2" zIndex={1}>
            {resources.heading}
          </Heading>
          <ResponsiveStack cols={3} spacing={4} zIndex={1}>
            {resources.items.map((resource) => (
              <ExternalLinkCard
                key={resource.url}
                heading={resource.heading}
                content={resource.description}
                href={resource.url}
                imageUrl={resource.image}
              />
            ))}
          </ResponsiveStack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Home;
