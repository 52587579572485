/* eslint-disable global-require */
/** @jsx jsx */
import React from 'react';
import NextLink from 'next/link';
import { css, jsx } from '@emotion/core';
import { Box, Flex, Icon, useTheme } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Link } from '@/components/ui';
import { Body } from '@/components/typography';
import { rem } from '@/utils/theme';
import type { CustomTheme } from '@/utils/theme';

const Header: React.FC<FlexProps> = ({ ...rest }) => {
  const theme = useTheme() as CustomTheme;
  const paddingBreakpoint = `${
    parseFloat(theme.sizes.contentMax) + parseFloat(theme.space['3'])
  }rem`;

  return (
    <Flex
      as="header"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.200"
      height={rem('72px')}
      justifyContent="center"
      width="full"
      {...rest}
    >
      <Flex justifyContent="space-between" maxWidth="contentMax" width="full">
        <NextLink href="/" passHref>
          <Flex
            as="a"
            alignItems="center"
            flexShrink={0}
            marginLeft={3}
            css={css`
              @media (min-width: ${paddingBreakpoint}) {
                margin-left: 0;
              }
            `}
          >
            <picture>
              <source
                srcSet={require('@/images/logo.png?webp')}
                type="image/webp"
              />
              <source srcSet={require('@/images/logo.png')} type="image/png" />
              <Box
                as="img"
                height={[rem('32px'), rem('40px')]}
                // https://github.com/chakra-ui/chakra-ui/issues/205#issuecomment-554731492
                {...{
                  alt: 'Legal Aid NSW Logo',
                  src: require('@/images/logo.png'),
                }}
              />
            </picture>
          </Flex>
        </NextLink>
        <Link
          href="https://www.lawaccess.nsw.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
          alignItems="center"
          display="flex"
          marginRight={3}
          css={css`
            @media (min-width: ${paddingBreakpoint}) {
              margin-right: 0;
            }
          `}
        >
          <Icon name="openInNew" mr={1} size="3" />
          <Body fontWeight="600">LawAccess NSW</Body>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Header;
