import React from 'react';
import type { FlexProps } from '@chakra-ui/core';

import { Body } from '@/components/typography';
import Card from '../Card';

type Props = {
  points: {
    heading: string;
    content: string;
  }[];
} & Omit<FlexProps, 'as'>;

const SummaryCard: React.FC<Props> = ({ points, ...rest }) => {
  return (
    <Card borderRadius="sm" {...rest}>
      <Card.Content>
        <Card.Heading variant="2">Your summary</Card.Heading>
        <Card.Body spacing={2} mt={4}>
          {points.map((point) => (
            <Body key={point.heading}>
              <b>{point.heading}:</b> {point.content}
            </Body>
          ))}
        </Card.Body>
      </Card.Content>
    </Card>
  );
};

export default SummaryCard;
