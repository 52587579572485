import React, { useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import { Header, StepIndicator } from '@/components/layout';
import type { Step } from '@/utils/types';

type Props = {
  stepIndicator?: {
    step: Step;
    question: number;
  };
} & FlexProps;

const Page: React.FC<Props> = ({ children, stepIndicator, ...rest }) => {
  // Apply polyfill for `position: sticky` on browsers that need it
  const stickyEl = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (window.CSS && window.CSS.supports('position', 'sticky')) {
      return;
    }

    const polyfillStickyHeader = async () => {
      const stickyfill = await import('stickyfilljs');
      if (stickyEl.current !== null) {
        stickyfill.addOne(stickyEl.current);
      }
    };
    polyfillStickyHeader();
  }, [stickyEl.current]);

  return (
    <Flex alignItems="center" direction="column" minHeight="full" {...rest}>
      <Flex
        direction="column"
        position="sticky"
        top={0}
        ref={stickyEl}
        width="full"
        zIndex={2}
      >
        <Header />
        {stepIndicator !== undefined && (
          <StepIndicator
            step={stepIndicator.step}
            question={stepIndicator.question}
          />
        )}
      </Flex>
      {children}
    </Flex>
  );
};

export default Page;
