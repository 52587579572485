import { useState } from 'react';
import pMinDelay from 'p-min-delay';

import {
  fetchExit,
  fetchOption,
  fetchOutcome,
  fetchQuestion,
} from '@/api/client';
import type { Dictionary } from '@/utils/types';
import type {
  ExitResponse,
  OptionResponse,
  OutcomeResponse,
  QuestionResponse,
} from '@/api/types';

type Data = {
  question: Dictionary<QuestionResponse | Error>;
  exit: Dictionary<ExitResponse | Error>;
  outcome: Dictionary<OutcomeResponse | Error>;
  option: Dictionary<OptionResponse | Error>;
};

// Delay fetch response to allow loading indicator to display
const minRequestTime = 500;

// Fetch content from the CMS by type and ID, cache it
const useData = () => {
  const [data, setData] = useState<Data>({
    question: {},
    exit: {},
    outcome: {},
    option: {},
  });
  const updateData = <T extends keyof Data>(key: T, obj: Data[T]) => {
    return setData({
      ...data,
      [key]: {
        ...data[key],
        ...obj,
      },
    });
  };
  const fetchData = <T extends keyof Data>(key: T, id: string) => {
    switch (key) {
      case 'question':
        updateData('question', { [id]: undefined });
        pMinDelay(fetchQuestion(id), minRequestTime)
          .then((response) => {
            updateData('question', { [id]: response });
          })
          .catch((err) => {
            updateData('question', { [id]: new Error(err) });
          });
        break;
      case 'exit':
        updateData('exit', { [id]: undefined });
        pMinDelay(fetchExit(id), minRequestTime)
          .then((response) => {
            updateData('exit', { [id]: response });
          })
          .catch((err) => {
            updateData('exit', { [id]: new Error(err) });
          });
        break;
      case 'outcome':
        updateData('outcome', { [id]: undefined });
        pMinDelay(fetchOutcome(id), minRequestTime)
          .then((response) => {
            updateData('outcome', { [id]: response });
          })
          .catch((err) => {
            updateData('outcome', { [id]: new Error(err) });
          });
        break;
      case 'option':
        updateData('option', { [id]: undefined });
        pMinDelay(fetchOption(id), minRequestTime)
          .then((response) => {
            updateData('option', { [id]: response });
          })
          .catch((err) => {
            updateData('option', { [id]: new Error(err) });
          });
        break;
      default:
        break;
    }
  };
  return [data, fetchData] as const;
};

export default useData;
