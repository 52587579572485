import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useMachine } from '@xstate/react';
import type { AnyEventObject, StateMachine, StateSchema } from 'xstate';
import type { NextRouter } from 'next/router';

import { Page } from '@/components/layout';
import {
  Calculating,
  Exit,
  LoadingError,
  OptionDetails,
  Outcome,
  Question as QuestionPage,
} from '@/components/pages';
import useData from '@/utils/useData';
import { Step, assertNever } from '@/utils/types';
import { generateSummary } from '@/pathways/summary';
import { getQuestionNo, shouldShowWarning } from '@/pathways/utils';
import type { Context, Question } from '@/pathways/types';

const getStateFromURL = (): [string[], string] => {
  const param =
    new URLSearchParams(window.location.search).get('q') ?? btoa('{}');
  const state = JSON.parse(atob(param));
  return [state.answers ?? [], state.option ?? ''];
};

const getURLFromState = (answers: string[], option: string): string => {
  const url = window.location.pathname;
  const json = JSON.stringify({ answers: answers, option: option });
  return `${url}?q=${btoa(json)}`;
};

const updateState = (
  fn: (
    answers: string[],
    option: string
  ) => {
    answers: string[];
    option: string;
  },
  router: NextRouter
) => {
  const [answers, option] = getStateFromURL();
  const newState = fn(answers, option);
  const url = getURLFromState(newState.answers, newState.option);
  router
    .push('/pathway/[...slug]', url, {
      shallow: true,
    })
    .then(() => {
      window.scrollTo(0, 0);
    });
};

type Props = {
  machine: StateMachine<Context, StateSchema, AnyEventObject>;
  questions: Question[];
};

const Pathway: React.FC<Props> = ({ machine, questions }) => {
  const router = useRouter();

  const [current, send, service] = useMachine(machine);
  const context = current.context;
  const meta = current.meta[`${service.machine.id}.${current.value}`];
  const type = meta.type as 'question' | 'exit' | 'outcome' | 'option';
  const id = String(current.value);

  // If user is sent to an outcome from a question, show "calculating" screen for 3 seconds
  const [loading, setLoading] = useState<string | null>(null);
  const previousType = useRef('');
  useLayoutEffect(() => {
    if (type === 'outcome' && previousType.current === 'question') {
      setLoading('Figuring out the best possible options for you');
      setTimeout(() => {
        setLoading(null);
      }, 3000);
    }
    previousType.current = type;
  }, [type]);

  // State is stored in URL query param, when it changes update the state machine
  useLayoutEffect(() => {
    if (service.initialized) {
      const [answers, option] = getStateFromURL();
      send(['RESET', ...answers]);
      if (option) {
        send({ type: 'OPTION', id: option });
      }
    }
  }, [router.query, service.initialized]);

  // Handle the back action for each step
  const onBack = () => {
    if (context.answers.length === 0) {
      router.push('/').then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    updateState((answers, option) => {
      // If no option is selected then remove one answer from the history
      if (option === '') {
        return { answers: answers.slice(0, -1), option: '' };
      }
      // Otherwise just clear the selected option
      return { answers: answers, option: '' };
    }, router);
  };

  // Fetch and cache content (e.g. question, outcome, etc.) from CMS as needed
  const [data, fetchData] = useData();
  useEffect(() => {
    switch (type) {
      case 'question':
      case 'exit':
      case 'outcome':
        if (data[type][id] === undefined) {
          fetchData(type, id);
        }
        break;
      case 'option': {
        const optionId = context.option ?? '';
        if (data.option[optionId] === undefined) {
          fetchData('option', optionId);
        }
        break;
      }
      default:
        assertNever(type);
    }
  }, [current]);

  if (loading !== null) {
    return (
      <Page>
        <Calculating text={loading} />
      </Page>
    );
  }

  switch (type) {
    case 'question': {
      const questionType = meta.questionType as 'Offence' | 'You';
      const step = questionType === 'Offence' ? 1 : 2;
      const questionNo = getQuestionNo(
        questionType,
        context.answers,
        questions
      );
      const content = data.question[id];
      if (content === undefined || content instanceof Error) {
        return (
          <Page stepIndicator={{ step: step, question: questionNo }}>
            {content === undefined && <Calculating text="" />}
            {content instanceof Error && (
              <LoadingError
                onClick={() => {
                  fetchData('question', id);
                }}
              />
            )}
          </Page>
        );
      }

      return (
        <Page stepIndicator={{ step: step, question: questionNo }}>
          <QuestionPage
            type={content.type}
            heading={content.heading}
            dialogs={content.dialogs}
            options={content.options}
            onSelect={(answer) => {
              updateState((answers, option) => {
                return {
                  answers: [...answers, answer],
                  option: option,
                };
              }, router);
            }}
            onBack={onBack}
          />
        </Page>
      );
    }
    case 'exit': {
      const content = data.exit[id];
      if (content === undefined || content instanceof Error) {
        return (
          <Page>
            {content === undefined && <Calculating text="" />}
            {content instanceof Error && (
              <LoadingError
                onClick={() => {
                  fetchData('exit', id);
                }}
              />
            )}
          </Page>
        );
      }

      return (
        <Page backgroundColor="brand.blue.faded">
          <Exit
            page={{
              heading: content.heading,
              explanation: content.explanation,
            }}
            links={{
              heading: content.linksHeading,
              startingPoints: content.startingPoints,
              resources: content.resources,
            }}
            onBack={onBack}
          />
        </Page>
      );
    }
    case 'outcome': {
      const content = data.outcome[id];
      if (content === undefined || content instanceof Error) {
        return (
          <Page>
            {content === undefined && <Calculating />}
            {content instanceof Error && (
              <LoadingError
                onClick={() => {
                  fetchData('outcome', id);
                }}
              />
            )}
          </Page>
        );
      }

      const summary = generateSummary(machine.id, context.answers);
      return (
        <Page stepIndicator={{ step: Step.Options, question: 0 }}>
          <Outcome
            intro={content.intro}
            summary={summary}
            options={content.options}
            secondary={content.secondary}
            onSelect={(optionId) => {
              updateState((answers) => {
                return {
                  answers: answers,
                  option: optionId,
                };
              }, router);
            }}
            onBack={onBack}
          />
        </Page>
      );
    }
    case 'option': {
      const optionId = context.option ?? '';
      const content = data.option[optionId];
      if (content === undefined || content instanceof Error) {
        return (
          <Page stepIndicator={{ step: Step.Options, question: 0 }}>
            {content === undefined && <Calculating text="" />}
            {content instanceof Error && (
              <LoadingError
                onClick={() => {
                  fetchData('option', optionId);
                }}
              />
            )}
          </Page>
        );
      }

      return (
        <Page stepIndicator={{ step: Step.Options, question: 0 }}>
          <OptionDetails
            page={{
              image: content.image,
              heading: content.heading,
              description: content.description,
            }}
            warnings={content.warnings.filter((w) =>
              shouldShowWarning(w, context.answers)
            )}
            considerations={content.considerations}
            resources={content.resources}
            onBack={onBack}
          />
        </Page>
      );
    }
    default:
      return assertNever(type);
  }
};

export default Pathway;
