import React from 'react';
import { Icon } from '@chakra-ui/core';
import type { IconProps } from '@chakra-ui/core';

type Props = IconProps;

const CardIcon: React.FC<Props> = ({ ...rest }) => {
  return <Icon color="brand.blue.primary" {...rest} />;
};

export default CardIcon;
