import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { StackProps } from '@chakra-ui/core';

type Props = StackProps;

const CardContent: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex
      direction="column"
      flexGrow={1}
      padding={[3, 5]}
      textAlign="left"
      width="full"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default CardContent;
