import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

import { Body } from '@/components/typography';

type Props = {
  num: number;
  active: boolean;
};

const StepItem: React.FC<Props> = ({ num, active, children }) => {
  return (
    <Flex alignItems="center" py={4}>
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="50%"
        color="brand.blue.primary"
        justifyContent="center"
        opacity={active ? 1 : 0.5}
        marginRight={1}
        size="1em"
        transition="opacity 0.3s"
      >
        <Text fontSize="0.75em" fontWeight="600">
          {num}
        </Text>
      </Flex>
      <Body
        borderY="2px solid"
        borderColor="transparent"
        borderBottomColor={active ? 'white' : 'transparent'}
        color="rgba(255, 255, 255, 0.5)"
        transition="border-color 0.3s"
      >
        {children}
      </Body>
    </Flex>
  );
};

export default StepItem;
