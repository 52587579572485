import { createMachine, getId } from '../utils';
import type { Question } from '../types';

export const id = 'LICENCE_SUSPENSION';

export const keys = {
  questions: {
    reason: getId(id, 'REASON'),
    moreThan28DaysAgo: getId(id, 'ISSUED_OVER_28_DAYS_AGO'),
    licenceType: getId(id, 'LICENCE_TYPE'),
    hasStarted: getId(id, 'HAS_STARTED'),
  },
  answers: {
    reason: {
      speeding: getId(id, 'REASON', 'SPEEDING'),
      demerits: getId(id, 'REASON', 'DEMERITS'),
      immediate: getId(id, 'REASON', 'IMMEDIATE'),
      other: getId(id, 'REASON', 'OTHER'),
    },
    moreThan28DaysAgo: {
      yes: getId(id, 'ISSUED_OVER_28_DAYS_AGO', 'YES'),
      no: getId(id, 'ISSUED_OVER_28_DAYS_AGO', 'NO'),
    },
    licenceType: {
      learner: getId(id, 'LICENCE_TYPE', 'LEARNER'),
      p1: getId(id, 'LICENCE_TYPE', 'P1'),
      p2: getId(id, 'LICENCE_TYPE', 'P2'),
      full: getId(id, 'LICENCE_TYPE', 'FULL'),
    },
    hasStarted: {
      yes: getId(id, 'HAS_STARTED', 'YES'),
      no: getId(id, 'HAS_STARTED', 'NO'),
    },
  },
  outcomes: {
    // Speeding offence, issued less than 28 days ago
    // Appeal your suspension in court OR serve your suspension
    one: getId(id, 'OUTCOME', 'ONE'),
    // Too many demerits, issued less than 28 days ago
    // Learner, P1 OR P2 licence
    // Appeal your suspension in court OR serve your suspension
    three: getId(id, 'OUTCOME', 'THREE'),
    // Too many demerits, issued less than 28 days ago
    // Full licence AND suspension has started
    // Serve your suspension
    four: getId(id, 'OUTCOME', 'FOUR'),
    // Too many demerits, issued less than 28 days ago
    // Full licence AND suspension hasn't started
    // Serve your suspension OR apply for a good behaviour licence
    five: getId(id, 'OUTCOME', 'FIVE'),
  },
  exits: {
    // Redirected to other pathways
    immediateSuspension: getId(id, 'EXIT', 'IMMEDIATE_SUSPENSION'),
    // Get legal advice
    other: getId(id, 'EXIT', 'OTHER'),
    // Get legal advice
    issued28DaysAgoSpeeding: getId(id, 'EXIT', 'ISSUED_28_DAYS_AGO_SPEEDING'),
    // Get legal advice
    issued28DaysAgoDemerits: getId(id, 'EXIT', 'ISSUED_28_DAYS_AGO_DEMERITS'),
  },
};

export const questions: Question[] = [
  {
    // What is the reason for your suspension?
    id: keys.questions.reason,
    type: 'Offence',
    answers: [
      {
        // Speeding offence
        id: keys.answers.reason.speeding,
        next: keys.questions.moreThan28DaysAgo,
      },
      {
        // Too many demerits
        id: keys.answers.reason.demerits,
        next: keys.questions.moreThan28DaysAgo,
      },
      {
        // Immediate suspension by a police officer'
        id: keys.answers.reason.immediate,
        next: keys.exits.immediateSuspension,
      },
      {
        // Other
        id: keys.answers.reason.other,
        next: keys.exits.other,
      },
    ],
  },
  {
    // Was the suspension issued more than 28 days ago?
    id: keys.questions.moreThan28DaysAgo,
    type: 'You',
    answers: [
      {
        // Yes
        id: keys.answers.moreThan28DaysAgo.yes,
        next: [
          {
            id: keys.exits.issued28DaysAgoDemerits,
            if: [keys.answers.reason.demerits],
          },
          {
            id: keys.exits.issued28DaysAgoSpeeding,
            if: [keys.answers.reason.speeding],
          },
        ],
      },
      {
        // No
        id: keys.answers.moreThan28DaysAgo.no,
        next: keys.questions.licenceType,
      },
    ],
  },
  {
    // What type of licence do you have?
    id: keys.questions.licenceType,
    type: 'You',
    answers: [
      {
        // Learner
        id: keys.answers.licenceType.learner,
        next: [
          {
            id: keys.outcomes.one,
            if: [keys.answers.reason.speeding],
          },
          {
            id: keys.outcomes.three,
            if: [keys.answers.reason.demerits],
          },
        ],
      },
      {
        // Provisional 1
        id: keys.answers.licenceType.p1,
        next: [
          {
            id: keys.outcomes.one,
            if: [keys.answers.reason.speeding],
          },
          {
            id: keys.outcomes.three,
            if: [keys.answers.reason.demerits],
          },
        ],
      },
      {
        // Provisional 2'
        id: keys.answers.licenceType.p2,
        next: [
          {
            id: keys.outcomes.one,
            if: [keys.answers.reason.speeding],
          },
          {
            id: keys.outcomes.three,
            if: [keys.answers.reason.demerits],
          },
        ],
      },
      {
        // Full
        id: keys.answers.licenceType.full,
        next: [
          {
            id: keys.outcomes.one,
            if: [keys.answers.reason.speeding],
          },
          {
            id: keys.questions.hasStarted,
            if: [keys.answers.reason.demerits],
          },
        ],
      },
    ],
  },
  {
    // Has your suspension already started?
    id: keys.questions.hasStarted,
    type: 'You',
    answers: [
      {
        // Yes
        id: keys.answers.hasStarted.yes,
        next: keys.outcomes.four,
      },
      {
        // No
        id: keys.answers.hasStarted.no,
        next: keys.outcomes.five,
      },
    ],
  },
];

export const outcomes = Object.values(keys.outcomes);

export const exits = Object.values(keys.exits);

export const machine = createMachine(id, questions, outcomes, exits);
