import React from 'react';
import { Flex, Stack } from '@chakra-ui/core';

import { ResponsiveStack } from '@/components/layout';
import { Heading } from '@/components/typography';
import { AnswerCard, BackButton, Dialog } from '@/components/ui';
import { rem } from '@/utils/theme';

type Props = {
  type: 'yesno' | 'multiplechoice';
  heading: string;
  dialogs: { id: string; heading: string; content: string }[];
  options: { id: string; heading: string; content?: string }[];
  onSelect: (id: string) => void;
  onBack: () => void;
};

const Question: React.FC<Props> = ({
  heading,
  type,
  dialogs,
  options,
  onSelect,
  onBack,
}) => {
  return (
    <Flex
      direction="column"
      pb={12}
      pt={[3, 10]}
      maxWidth="contentMax"
      width="full"
    >
      <BackButton alignSelf="flex-start" padding={3} onClick={onBack} />
      <Flex direction="column" px={3} width="full">
        <Heading
          color="brand.text"
          variant="2"
          mt={[3, 6]}
          maxWidth={rem('600px')}
        >
          {heading}
        </Heading>
        {dialogs.map((dialog) => (
          <Dialog
            key={dialog.id}
            maxWidth={['none', rem('400px')]}
            mt={5}
            heading={dialog.heading}
            content={dialog.content}
          />
        ))}
        {type === 'yesno' ? (
          <Stack marginTop={[6, 12]} spacing={[5, 3]} isInline>
            {options.map((option) => (
              <AnswerCard
                key={option.id}
                as="button"
                maxWidth={['none', rem('400px')]}
                heading={option.heading}
                content={option.content}
                onClick={() => {
                  onSelect(option.id);
                }}
              />
            ))}
          </Stack>
        ) : (
          <ResponsiveStack cols={3} mt={[6, 12]} colSpacing={3} rowSpacing={5}>
            {options.map((option) => (
              <AnswerCard
                key={option.id}
                as="button"
                heading={option.heading}
                content={option.content}
                onClick={() => {
                  onSelect(option.id);
                }}
              />
            ))}
          </ResponsiveStack>
        )}
      </Flex>
    </Flex>
  );
};

export default Question;
