import React from 'react';

import { Image } from '@/components/ui';

type Props = Partial<React.ComponentProps<typeof Image>>;

const CardImage: React.FC<Props> = ({ ...rest }) => {
  return (
    <Image
      alt=""
      borderBottom="6px solid"
      borderBottomColor="brand.red.primary"
      boxSizing="content-box"
      height={['160px', '200px']}
      objectFit="cover"
      width="full"
      {...rest}
    />
  );
};

export default CardImage;
