import React from 'react';
import { Flex } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import {
  CardBody,
  CardContent,
  CardHeading,
  CardIcon,
  CardImage,
} from './components';

type Props = {
  hasBorder?: boolean;
} & (({ as: 'a'; href: string } & Omit<FlexProps, 'as'>) | FlexProps);

interface CardSubComponents {
  Body: typeof CardBody;
  Content: typeof CardContent;
  Heading: typeof CardHeading;
  Icon: typeof CardIcon;
  Image: typeof CardImage;
}

const Card: React.FC<Props> & CardSubComponents = ({
  as = 'div',
  hasBorder = false,
  children,
  ...rest
}) => {
  let extra = {};
  if (as === 'a') {
    extra = { target: '_blank', rel: 'noopener noreferrer' };
  }

  return (
    <Flex
      as={as}
      backgroundColor="white"
      borderTop={hasBorder ? '6px solid' : 'none'}
      borderTopColor="brand.red.primary"
      boxShadow="card"
      borderBottomLeftRadius="sm"
      borderBottomRightRadius="sm"
      flexDirection="column"
      height="full"
      lineHeight="shorter"
      width="full"
      {...extra}
      {...rest}
    >
      {children}
    </Flex>
  );
};

Card.Body = CardBody;
Card.Content = CardContent;
Card.Heading = CardHeading;
Card.Icon = CardIcon;
Card.Image = CardImage;

export default Card;
