/* eslint-disable react/no-array-index-key */
import React, { isValidElement } from 'react';
import { Flex, Stack, StackProps } from '@chakra-ui/core';
import chunk from 'lodash.chunk';

type Props = {
  cols: number;
  rowSpacing?: string | number;
  colSpacing?: string | number;
  spacing?: string | number;
} & StackProps;

// TODO: Change Flex columns to Stacks once they support responsive direction
// https://github.com/chakra-ui/chakra-ui/issues/497
const ResponsiveStack: React.FC<Props> = ({
  cols,
  spacing = 0,
  rowSpacing,
  colSpacing,
  children,
  ...rest
}) => {
  const cSpacing = colSpacing !== undefined ? colSpacing : spacing;
  const rSpacing = rowSpacing !== undefined ? rowSpacing : spacing;
  // Calculate props for the item containers
  const flexBasis = `${100 / cols}%`;
  const itemProps = {
    flexBasis: ['auto', flexBasis],
    mr: ['0', `${cSpacing}`],
    mb: [`${rSpacing}`, '0'],
  };

  // Split the items in to chunks based on number of columns
  const validChildren = React.Children.toArray(children).filter(isValidElement);
  const itemRows = chunk(validChildren, cols);

  return (
    <Stack spacing={rSpacing} {...rest}>
      {itemRows.map((itemRow, i) => {
        const { length } = itemRow;
        // If needed add empty items to ensure there's enough columns
        for (let j = itemRow.length; j < cols; j += 1) {
          itemRow.push(<></>);
        }
        return (
          <Flex key={i} direction={['column', 'row']}>
            {itemRow.map((item, j) => {
              const props = { ...itemProps, display: ['flex'] };
              // Remove margin from last item of column/row
              if (j === length - 1) {
                props.mb = ['0', '0'];
                props.mr = ['0', '0'];
              }
              // Hide the "empty" items on mobile
              if (item.type === React.Fragment) {
                props.display = ['none', 'flex'];
              }
              return (
                <Flex key={j} {...props}>
                  {item}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Stack>
  );
};

export default ResponsiveStack;
