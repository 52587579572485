import React from 'react';
import { Box, Stack } from '@chakra-ui/core';

import { Heading } from '@/components/typography';
import { Loader } from '@/components/ui';
import { rem } from '@/utils/theme';

type Props = {
  onClick: () => void;
};

const LoadingError: React.FC<Props> = ({ onClick }) => {
  return (
    <Stack
      alignItems="center"
      maxWidth={rem('350px')}
      px={3}
      py={10}
      spacing={5}
    >
      <Loader disabled width={rem('128px')} />
      <Heading
        variant="3"
        color="brand.blue.primary"
        textAlign="center"
        width="full"
      >
        Oops! Something went wrong.
      </Heading>
      <Box
        as="button"
        onClick={onClick}
        backgroundColor="brand.blue.primary"
        borderRadius="sm"
        color="white"
        fontSize="xs"
        fontWeight="600"
        marginTop={5}
        paddingX={10}
        paddingY={3}
      >
        Try again
      </Box>
    </Stack>
  );
};

export default LoadingError;
